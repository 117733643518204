import React from 'react'
import { Box } from '@mui/material'
import SingleReport from "./SingleReport";
import useApiQuery from "hooks/useApiQuery";
import { LOUNGE } from "services/constants";
import NoData from "components/NoData";
import Error from "components/Error";
import Loader from "components/Loader";
const Reports = ({ searchTerm, limit, offset, setTotal }) => {

    const {
        data: apiResponse,
        isLoading,
        isError,
        error,
    } = useApiQuery({
        queryKey: ["lounge-reports", limit, offset, searchTerm],
        url: LOUNGE + `/reports?searchTerm=${searchTerm}&offset=${offset}&limit=${limit}`,
        searchTerm,
        otherOptions: {
            onSuccess: ({ data }) => {
                setTotal(data.total)
            },
        },
    });

    if (isLoading) return <Loader />;
    if (isError) return <Error error={error} />

    return (
        <Box mt={3}>
            {apiResponse.data.reports.length > 0 ? apiResponse.data.reports.map((reportData) => (
                <SingleReport key={reportData._id} {...reportData} />
            )) : <NoData message="No Reports Exist" />}

        </Box>
    )
}

export default Reports