import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
    Box,
    Grid
} from "@mui/material";
import { useQueryClient } from "react-query";
import { CustomButton } from "components";
import { PRACTICE } from "services/constants";
import { toast } from "react-toastify";
import ErrorMsg from "components/ErrorMsg";
import useApiMutation from "hooks/useApiMutation";
import QuillEditor from "components/QuillEditor/QuillEditor";
import CustomDropZone from "components/DropZone/CustomDropzone";
import AwsS3 from "utils/S3Intergration";

const CreatePractice = ({ setOpen, practiceData = null }) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useApiMutation();
    const [loading, setLoading] = useState(false);

    const fetchPractices = () => queryClient.invalidateQueries({ queryKey: "practices" });

    const handleSuccess = ({ message }) => {
        setOpen(false);
        toast.success(message);
        fetchPractices();
    };
    const initalValues = {
        description: practiceData?.description || "",
        file: practiceData?.file || [],
    };

    const validationSchema = yup.object().shape({
        description: yup.string().required("Description is required"),
        file: yup.array().min(1, "At least one file is required"),
    });

    const { setFieldValue, values, handleSubmit, errors } = useFormik({
        initialValues: initalValues,
        validationSchema: validationSchema,
        onSubmit: async ({ file, description }) => {
            const fileData = [];

            try {
                setLoading(true);
                for (let i = 0; i < file.length; i++) {
                    const { type, name } = file[i];
                    const url = await new AwsS3(file[i], "images/").getS3URL();
                    fileData.push({
                        url,
                        type,
                        extension: type.split('/')[1],
                        name,
                    })
                }


            } catch (error) {
                console.log('something went wrong')
            } finally {
                setLoading(false)
            }

            const practiceValues = { file: fileData, description }

            mutate(
                {
                    url: PRACTICE + (practiceData ? 'update' : "create"),
                    data: practiceData ? { ...practiceValues, id: practiceData._id } : practiceValues
                },
                {
                    onSuccess: handleSuccess,
                }
            );
        },
    });
    const { description } = values;

    return (
        <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap="12px">
                <Box>
                    <QuillEditor
                        name="description"
                        value={description}
                        onChange={(value) => setFieldValue("description", value)}
                    />
                    {errors.description && <ErrorMsg error={errors.description} />}
                </Box>
                <Box>
                    <Grid container>
                        <Grid item sm={6} md={4} spacing={2}>
                            <CustomDropZone type='image' handleFileChange={(files) => setFieldValue('file', files)} />
                        </Grid>
                    </Grid>
                    {errors.file && <ErrorMsg error={errors.file} />}
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <CustomButton disabled={isLoading || loading} onClick={() => setOpen(false)}>
                        Cancel
                    </CustomButton>
                    <CustomButton disabled={isLoading || loading} type="submit">
                        {practiceData ? "Update" : "Add"} {(isLoading || loading) && "..."}
                    </CustomButton>
                </Box>
            </Box>
        </form>
    );
};

export default CreatePractice;
