import { apiRequestFn } from "../utils/index";

const { useQuery } = require("react-query")
const useApiQuery = ({
  queryKey = "",
  url,
  data,
  apiOptions = {},
}) => {
  return useQuery(
    queryKey,
    () => apiRequestFn({ url, ...(data && { data }), ...apiOptions }),
  );
}


export default useApiQuery;