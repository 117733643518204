import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const StyledDescription = styled(Typography)(
  ({ theme, limitLines, color }) => ({
    color: color ?? theme.palette.grey[600],
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: limitLines,
    WebkitBoxOrient: "vertical",
    lineHeight: '1.5em',
    minHeight: limitLines * 1.9 + 'em'
  }),
);
