import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

import {
  Login,
  ForgotPassword,
  ResetPassword,
  Blogs,
  BluePrint,
  BookClub,
  Clips,
  Library,
  Whistle,
  Dashboard,
  Lounges,
  Tapes,
  Practice,
  Users,
  AddUser,
  Plans,
  Promos,
  Promotions,
  Faqs,
  NotFound,
} from "../pages";
import { AuthLayout, AdminLayout } from "../layout";
import authHandler from "../managers/auth";
import UserReports from "pages/user-managment/report-requests";

const Routing = () => {

  const ProtectedRoute = () => {
    const token = authHandler.getToken();

    if (!token) {
      return <Navigate to="/login" replace />;
    }
    return <Outlet />;
  };

  return (
    <Router>
      <Routes>
        {/* Auth Routes */}
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/setnewpassword/:id" element={<ResetPassword />} />
        </Route>

        {/* Admin Routes */}
        <Route element={<ProtectedRoute />}>
          <Route element={<AdminLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/tape" element={<Tapes />} />
            <Route path="/practice" element={<Practice />} />
            <Route path="/user/list" element={<Users />} />
            <Route path="/user/reports" element={<UserReports />} />
            <Route path="/user/add" element={<AddUser />} />
            <Route path="/bookclub" element={<BookClub />} />
            <Route path="/clips" element={<Clips />} />
            {/* <Route path="/settings" element={<Home />} /> */}
            <Route path="/users" element={<Users />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/promotions" element={<Promotions />} />
            <Route path="/promos" element={<Promos />} />
            <Route path="/library" element={<Library />} />
            <Route path="/whistle" element={<Whistle />} />
            <Route path="/blueprint" element={<BluePrint />} />
            <Route path="/lounge" element={<Lounges />} />
            <Route path="/faqs" element={<Faqs />} />
          </Route>
        </Route>

        {/* 404 Page */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default Routing;
