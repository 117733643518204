import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { Grid, Typography, IconButton, Box } from "@mui/material";
import { Add } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import AwsS3 from "utils/S3Intergration";
import BlogApi from "../../../services/api/blog";
import {
  CustomButton,
  ConfirmDialog,
  CustomBadge,
  CustomStatusDialog,
  CustomFormDialog,
  CustomDescription,
  CustomLoader,
} from "../../../components";
import { blogInitialValues, addBlogFormElements } from "../../../constants";
import { blogValidationSchema } from "../../../utils/validation";

import { StyledCardMedia, StyledCard } from "./style";
import { shadows, vorameColors } from "theme/constants";

const Blogs = () => {
  const [blogID, setBlogID] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [deleteID, setDeleteID] = useState(null);
  const [openFormDialog, setOpenFormDialog] = React.useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [imageProgress, setImageProgress] = useState(0);
  const [videoPreview, setVideoPreview] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  // Get blogs list
  const {
    data: blogList,
    refetch,
    isLoading,
  } = useQuery("BLOG_LIST", () => BlogApi.getBlogs());

  // Get single blot
  const { data: singleBlog } = useQuery(
    ["SINGLE_BLOG", blogID],
    () => BlogApi.getSingleBlog({ id: blogID }),
    {
      enabled: !!blogID,
    },
  );

  // Update blog status mutation
  const updateStatusMutation = useMutation(
    (data) => BlogApi.updateBlogStatus(data),
    {
      onSuccess: () => {
        toast.success("Status updated successfully!");
        refetch();
      },
      onError: () => {
        toast.error("Failed to update status.");
      },
    },
  );

  // Update blog favourite mutation
  const updateFavoriteMutation = useMutation(
    (data) => BlogApi.updateBlogFavourite(data),
    {
      onSuccess: () => {
        toast.success("Favorite status updated successfully!");
        refetch();
      },
      onError: () => {
        toast.error("Failed to update favorite status.");
      },
    },
  );

  // Create and update blog mutation
  const { mutate: Blogs, isLoading: blogMutationLoading } = useMutation(
    (body) => {
      if (blogID) {
        const updatedBody = { ...body, id: blogID };
        return BlogApi.updateBlog(updatedBody);
      } else {
        return BlogApi.createBlog(body);
      }
    },
    {
      onSuccess: (res) => {
        if (res?.statusCode === 201 || res?.statusCode === 200) {
          toast.success(res?.message);
          refetch();
          handleClose();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    },
  );

  // Set form values on update

  useEffect(() => {
    if (singleBlog) {
      formik.setFieldValue("title", singleBlog?.findBlog?.title);
      formik.setFieldValue("description", singleBlog?.findBlog?.description);
      setImagePreview(singleBlog?.findBlog?.file[0]?.url);

      // Set image
      const newFile = {
        url: singleBlog?.findBlog?.file[0]?.url,
        type: singleBlog?.findBlog?.file[0]?.type,
        extension: singleBlog?.findBlog?.file[0]?.extension,
        name: singleBlog?.findBlog?.file[0]?.name,
      };

      formik.setFieldValue("file", [newFile]);
    }
  }, [singleBlog]);

  // Hanlde open delete confrim
  const handleConfirmOpen = (id) => {
    setDeleteID(id);
    setConfirmOpen(true);
  };

  // Handle delete blog api
  const handleDelete = async (event) => {
    event.preventDefault();
    const response = await BlogApi.deleteBlog(deleteID);
    if (response?.statusCode === 200) {
      toast.success(response.message);
      setConfirmOpen(false);
      setDeleteID(null);
      refetch();
    } else {
      toast.error("Blog not deleted!");
    }
  };

  // Handle badge click
  const handleBadgeClick = (blog) => {
    setBlogID(blog._id);
    setStatus(blog.status);
    setDialogOpen(true);
  };

  // Handle update blog  status method
  const handleUpdateStatus = () => {
    updateStatusMutation.mutate({ id: blogID, status });
    setDialogOpen(false);
  };

  // Handle update blog favourite method
  const handleFavoriteUpdate = (blog) => {
    updateFavoriteMutation.mutate({
      id: blog?._id,
      favourite: !blog?.favourite,
    });
  };

  // Formik
  const formik = useFormik({
    initialValues: blogInitialValues,
    validationSchema: blogValidationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  // Handle File change
  const handleFileChange = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileName.split(".").pop();
    const fileUrl = URL.createObjectURL(file);

    if (fileType.startsWith("image/")) {
      let url;
      try {
        url = await new AwsS3(file, "images/").getS3URLWithProgress(
          (progress) => {
            const percent = Math.round(
              (progress.loaded / progress.total) * 100,
            );
            setImageProgress(percent);
          },
        );
        setImagePreview(fileUrl);
        setVideoPreview(null);
        setVideoProgress(0);
        const newFile = {
          url: url,
          type: fileType,
          extension: fileExtension,
          name: fileName,
        };

        formik.setFieldValue("file", [...formik.values.file, newFile]);
      } catch (error) {
      }
    }
  };

  // Handle blog form submit
  const handleSubmit = async (values) => {
    Blogs(values);
  };

  // Open add blog
  const handleClickOpen = (id) => {
    if (id) {
      setBlogID(id);
    }
    setOpenFormDialog(true);
  };

  // Close add blog
  const handleClose = () => {
    setOpenFormDialog(false);
    formik.resetForm();
    setBlogID(null);
    setImagePreview(null);
    setImageProgress(0);
  };

  return (
    <>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <>
          <Grid container sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={6}>
              <Typography variant="h4">Blogs</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <CustomButton
                startIcon={<Add />}
                onClick={() => handleClickOpen(null)}
              >
                Add
              </CustomButton>
            </Grid>
          </Grid>
          {blogList && blogList.length > 0 ? (
            <Grid spacing={4} container sx={{ overflowY: "auto" }}>
              {blogList.map((blog, index) => (
                <Grid item md={6} xs={12} key={index}>
                  <Box borderRadius='12px' py='12px' px={2} boxShadow={shadows.softDepthShadow} bgcolor='white'>
                    <Box display='flex' justifyContent='center'>
                      <StyledCardMedia
                        component="img"
                        image={blog.file[0]?.url}
                        alt={blog.title}
                      />
                    </Box>
                    <Box className="blog-details" mt='27px'>
                      <Typography variant="subtitle1" color={vorameColors.mirage} fontWeight={500}>
                        {blog.title}
                      </Typography>
                      {/* Custom description view in blogs */}
                      <CustomDescription
                        description={blog?.description}
                        limit={1}
                      />
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                          <CustomBadge
                            badgeContent={blog?.status}
                            onClick={() => handleBadgeClick(blog)}
                          />
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <IconButton
                            aria-label="start"
                            onClick={() => handleFavoriteUpdate(blog)}
                          >
                            {blog?.favourite === true ? (
                              <img
                                src={`icons/star-active.svg`}
                                alt="star-active"
                              />
                            ) : (
                              <img src={`icons/star.svg`} alt="star" />
                            )}
                          </IconButton>
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleClickOpen(blog?._id)}
                          >
                            <img src={`icons/edit.svg`} alt="edit" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleConfirmOpen(blog?._id)}
                          >
                            <img src={`icons/trash.svg`} alt="delete" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>

                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid>
              <Typography variant="subtitle1">
                Currently blogs not exists.
              </Typography>
            </Grid>
          )}
          <Grid>
            <ConfirmDialog
              title="Delete Blog ?"
              dialogContext="Are you sure to delete blog ?"
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={handleDelete}
            />
            <CustomStatusDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              onUpdate={handleUpdateStatus}
              status={status}
              setStatus={setStatus}
            />
          </Grid>
          {/* Add blog grid */}
          <CustomFormDialog
            dialogTitle={blogID ? "Update to Blog" : "Add to Blog"}
            open={openFormDialog}
            onClose={handleClose}
            formik={formik}
            handleFileChange={handleFileChange}
            formElements={addBlogFormElements}
            id={blogID}
            loading={blogMutationLoading}
            videoProgress={videoProgress}
            setVideoProgress={setVideoProgress}
            imageProgress={imageProgress}
            setImageProgress={setImageProgress}
            videoPreview={videoPreview}
            setVideoPreview={setVideoPreview}
            imagePreview={imagePreview}
            setImagePreview={setImagePreview}
          />
        </>
      )}
    </>
  );
};

export default Blogs;
